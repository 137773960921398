import React, { useRef } from "react";
import ECLogo from "./svgs/ecLogo";
import FHISELogo from "./svgs/fhISELogo";
//import LangToggle from "./langToggle"; //replaced by CLToggleComps
//import CountryToggle from "./countryToggle"; //replaced by CLToggleComps
import CLToggleComps from "./CLToggleComps";
import { ActiveCountryFlag } from "./countryToggle";
import SwissCross from "./svgs/swissCross";
import SwissEcLogo from "./svgs/swissEcLogo"

import { useTranslation } from "../hooks/customHooks";

import { menuMap } from '../data/menuMap';

const rootUrl = window.Root_URL;
const { activePage, activeSubPage } =
  (window.ecAppState && window.ecAppState.page) || "";
  
export default function Nav() {
  const country = JSON.parse(sessionStorage.getItem("locale")).country;
  const { t } = useTranslation();
  const activeDropDown = useRef(null);
  const handleSubClick = (link) => {
    activeDropDown.current = link;
  };
  // use Swiss Menu if country=="CH"; otherwise use default map
  var currentMenuMap = menuMap;

  const WithSubMenu = ({ main, sub }) => {
		
		
    return (
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href={`#`}
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          is-active-page={`${activePage === main}`}
        >
          {t(`mainMenu.${main}`)}
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          {sub.map((subItem, i) => {
            // if (subItem["availableFor"].indexOf(country) !== -1 || isDevelopmentVersion) {
						if (subItem["availableFor"].indexOf(country) !== -1) {

              return (
                  <a
                      onClick={() => handleSubClick(subItem.url)}
                      className={`dropdown-item ${
                          activeSubPage === subItem.name ? "active" : ""
                      }`}
                      href={`${rootUrl}${subItem.url}`}
                      key={`submenu_${subItem.name}_${i}`}
                  >
                    {t(`mainMenu.${subItem.name}`)}
                  </a>
              );
            }
          })}
        </div>
      </li>
    );
  };

  const NurMain = ({ main, mainUrl }) => {
    return (
      <li className="nav-item">
        <a
          className="nav-link"
          href={`${rootUrl}${mainUrl}`}
          is-active-page={`${activePage === main}`}
        >
          {t(`mainMenu.${main}`)}
        </a>
      </li>
    );
  };
  return (
    <>     
      <nav className="navbar ec-navbar-expand-xl navbar-light">
        <a className="navbar-brand" href={`${rootUrl}index.html`}>
          {country=="CH" && 
          <div>
            <span className={"SwissCrossContainer"} style={{"marginRight": "1em"}}><SwissCross className="brandLogoSwiss"/></span>
            <SwissEcLogo className="brandLogoSwiss"/>
          </div>}
          {country!="CH" &&
          <div>
            <ECLogo className="brandLogo" logoDashclass={"logoDash"} />
            <ActiveCountryFlag/>
          </div>}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <div className="menuContent">
            <div className="centerMenu">
              <ul className="navbar-nav">
                {currentMenuMap.map(
                  ({ main, availableFor, mainUrl = false, sub = false, ext = false }, i) => {
                    if (!mainUrl) {
                      // if (availableFor.indexOf(country) !== -1 || isDevelopmentVersion) {
                      if (availableFor.indexOf(country) !== -1) {
                        return <WithSubMenu
                            main={main}
                            sub={sub}
                            key={`main_${main}_${i}`}
                        />
                      }  else {
                        return "";
                      }
                    } else {
                      return <NurMain
                          main={main}
                          mainUrl={mainUrl}
                          key={`main_${main}_${i}`}
                      />
                    }
                    // return !mainUrl ? (
                    //   <WithSubMenu
                    //     main={main}
                    //     sub={sub}
                    //     key={`main_${main}_${i}`}
                    //   />
                    // ) : (
                    //   <NurMain
                    //     main={main}
                    //     mainUrl={mainUrl}
                    //     key={`main_${main}_${i}`}
                    //   />
                    // );
                  }
                )}
              </ul>
            </div>
            <div className="rightMenu">
              {country=="CH" &&
              <a href="https://www.tnc.ch/" title="TNC - Advanced Energy Concepts" target="_blank" rel="noopener noreferrer">
                <img src={`${rootUrl}img/TNC_logo.png`} style={{"height": "1.3rem","marginRight": "1rem"}}/>
              </a>
              }
              <a href={t("ISEUrl")} target="_blank" rel="noopener noreferrer">
                <FHISELogo className="brandLogo fhBrand" />
              </a>
              {/* load country/language Toggle Component */}
              <div className="localeElements_nav">
                <CLToggleComps />
              </div>

              {/*
                <div className="rightMenuButtonVAlign">
                  <CountryToggle />
                  {t("countryLabel")}
                </div>
                <div className="rightMenuButtonVAlign">
                  <LangToggle />
                  {t("langLabel")}
                </div>
              */}

            </div>
          </div>
        </div>
      </nav>
      {/*ACTIVATE SiteInfo // Maintenance / Announcement Banner here! 
      Activate/deactivate next line/Element and setup condition for country for correct usage! & change Text in dictionary*/}
      {/*country == "DE" && <SiteInfo /> */}
    </>
  );
}

// Initialize popover and tooltip here since every page will have the navbar
$("document").ready(() => {
  // Initialize popover
  $('[data-toggle="popover"]').popover();
  // Initialize tooltip
  $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" });
  $(".stopHover").hover(function(e) {
    e.stopPropagation();
  });
});
