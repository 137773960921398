import React, { useState, useRef } from "react";
// import { countryOptions, languageOptions } from "../utils/lang";
import { LangIcon } from "./svgs/icons";
import { updateLocale } from "../../utils/helperFunctions";
import { useTranslation } from "../hooks/customHooks";

const { isDevelopmentVersion } = window.ecAppState;
const { countryOptions, languageOptions } = window.ecAppState;
const { t } = useTranslation();

  /* Temp Measures
   * introduced to allow specific selection of language (import utils/lang.js deactivated). 
   * "langauges" is imortant and overrules utils/lang.js settings (L32). Allows hidden use of lang (not listed in menu), 
   * "value" not used here but kept for same syntax
  */

 const tempCountryOptions = {
      //countries with full available content
      DE: { value: t("de_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      CH: { value: t("ch_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      //countries with reduced website/data content
      EU: { value: t("eu_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      ALL: { value: t("all_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      //
      AL: { value: t("al_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      AM: { value: t("am_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      AT: { value: t("at_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      AZ: { value: t("az_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      BA: { value: t("ba_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      BE: { value: t("be_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      BG: { value: t("bg_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      BY: { value: t("by_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      CZ: { value: t("cz_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      DK: { value: t("dk_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      EE: { value: t("ee_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      ES: { value: t("es_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      FI: { value: t("fi_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      FR: { value: t("fr_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      GE: { value: t("ge_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      GR: { value: t("gr_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      HR: { value: t("hr_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      HU: { value: t("hu_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      IE: { value: t("ie_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      IT: { value: t("it_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      LT: { value: t("lt_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      LU: { value: t("lu_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      LV: { value: t("lv_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      MD: { value: t("md_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      ME: { value: t("me_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      MK: { value: t("mk_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      MT: { value: t("mt_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      NIE: { value: t("nie_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      NL: { value: t("nl_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      NO: { value: t("no_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      PL: { value: t("pl_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      PT: { value: t("pt_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      RO: { value: t("ro_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      RS: { value: t("rs_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      RU: { value: t("ru_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      SE: { value: t("se_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      SI: { value: t("si_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      SK: { value: t("sk_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      TR: { value: t("tr_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      UA: { value: t("ua_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      UK: { value: t("uk_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      XK: { value: t("xk_Ctoggle"), languages: ["de", "en", "fr", "it", "es"] },
      //countries hidden (but available in development)
      //GB: { value: t("gb_Ctoggle")", languages: ["de", "en", "fr", "it"] },  
  };    

const handleMouseOver = (txt) => {
  // alert(txt);
};

export default function LangToggle() {
  const { t } = useTranslation();
  const togRef = useRef(null);
  const [locale, setLocale] = useState(sessionStorage.getItem("locale"));
  const { lang, country } = JSON.parse(locale);
  // const langArr = countryOptions[country].languages;

  // Temp measures - application state should be in sync with world state
  const langArr = tempCountryOptions[country]
    ? tempCountryOptions[country].languages
    : countryOptions[country].languages;

  return (
    <div className="btn-group" data-toggle="tooltip" title={t("langChange")}>
      <button
        ref={togRef}
        type="button"
        className="langToggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onMouseOver={() => handleMouseOver("Change language")}
      >
        <LangIcon className="flagIcon" />
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        style={{ minWidth: "10px", padding: ".5rem"}}
      >
        {/*<!--className="dropdown-menu dropdown-menu-right pre-scrollable scrollable-menu"*/}
        <p className="dropdown-header" style={{ padding: "0 0 0.2rem 0" }}>
          {t("langChange")}
        </p>
        <div className="dropdown-divider"></div>
          <div className="pre-scrollable scrollable-menu-overflow">
          {langArr.map((key, i) => {
            return (
              <button
                className={"dropdown-item stopHover"+(lang==key && " active" || "")}
                key={`${key}_${i}`}
                type="button"
                aria-label="toggle language"
                style={{
                  padding: "0",
                  lineHeight: "0",
                  marginBottom: ".1rem",
                  pointerEvents: "auto",
                }}
                onClick={(e) => updateLocale(e, key, country)}
              >
                <p className="optText-nav">{languageOptions[key]}</p>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
