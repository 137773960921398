import React from "react";

const fhcls1 = {
  fill: "none",
};
const fhcls2 = {
  fill: "#00a886",
};
const fhcls3 = {
  fill: "#fff",
};
const fhcls4 = {
  clipPath: "url(#clip-path)",
};
const fhcls5 = {
  fill: "#231f20",
};

export default function FHISELogo({ className }) {
  return (
    <svg
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 170.09 46.53"
      className={className}
    >
      <defs>
        <style></style>
        <clipPath id="clip-path">
          <rect
            style={fhcls1}
            x="36.87"
            y="7.53"
            width="133.22"
            height="20.49"
          />
        </clipPath>
      </defs>
      <title>Fraunhofer ISE</title>
      <path
        style={fhcls2}
        d="M0,27.71A241.56,241.56,0,0,0,27.58,10.88V8.2A239.59,239.59,0,0,1,0,23.54Z"
      />
      <path
        style={fhcls2}
        d="M0,19.7A210.74,210.74,0,0,0,27.58,6.14V4.82A121.8,121.8,0,0,1,0,14.28Z"
      />
      <path
        style={fhcls2}
        d="M27.58,27.72V14.24c-6.28,5.29-12.5,9.88-17.72,13.48Z"
      />
      <path
        style={fhcls2}
        d="M6.33,27.72a193,193,0,0,0,17-11.28c1.4-1,2.83-2.13,4.27-3.24V11.53a234.73,234.73,0,0,1-25,16.19Z"
      />
      <path
        style={fhcls2}
        d="M0,12.32A108.8,108.8,0,0,0,27.58,3.77V2.26A84.11,84.11,0,0,1,0,9.13Z"
      />
      <path
        style={fhcls2}
        d="M0,0V7.72l1.84-.13a78.68,78.68,0,0,0,25.73-6V0Z"
      />
      <path
        style={fhcls3}
        d="M0,7.72V9.13A84.11,84.11,0,0,0,27.58,2.26V1.62a78.68,78.68,0,0,1-25.73,6Z"
      />
      <path
        style={fhcls3}
        d="M0,14.28A121.8,121.8,0,0,0,27.58,4.82V3.77A108.8,108.8,0,0,1,0,12.32Z"
      />
      <path
        style={fhcls3}
        d="M0,23.54A239.59,239.59,0,0,0,27.58,8.2V6.14A210.74,210.74,0,0,1,0,19.7Z"
      />
      <path
        style={fhcls3}
        d="M6.33,27.72H9.86c5.22-3.6,11.44-8.19,17.72-13.48v-1c-1.44,1.12-2.87,2.2-4.27,3.24A193,193,0,0,1,6.33,27.72Z"
      />
      <path
        style={fhcls3}
        d="M0,27.72H2.57a234.73,234.73,0,0,0,25-16.19v-.64A241.56,241.56,0,0,1,0,27.71Z"
      />
      <g style={fhcls4}>
        <path
          d="M162.07,27.71h3.55v-5c0-2,0-5.56,2.88-5.56a3.6,3.6,0,0,1,1.59.29V13.86a3.88,3.88,0,0,0-1.09-.11,4.18,4.18,0,0,0-3.73,3.41h-.05v-3.1h-3.15Zm-3.55-3.65A9.21,9.21,0,0,1,154,25.43,3.57,3.57,0,0,1,150.1,22h9.42c0-4.77-1.43-8.23-6.49-8.23-4.23,0-6.33,3.26-6.33,7.2,0,4.48,2.62,7.07,7.12,7.07a9.36,9.36,0,0,0,4.69-1.09Zm-8.42-4.5c.16-1.83,1.16-3.2,3.15-3.2s2.78,1.48,2.89,3.2Zm-11.33,8.16h3.55v-11h3.1V14.07h-3.1V12.37c0-1.06.37-2.09,1.57-2.09a2.73,2.73,0,0,1,1.53.48l.29-2.94a12.54,12.54,0,0,0-2.59-.29c-2.81,0-4.34,1.91-4.34,4.63v1.91h-2.62v2.59h2.62Zm-14.11-7.15c0-2,1.11-4.05,3.42-4.05s3.44,2,3.44,4.05c0,2.28-.71,4.71-3.44,4.71s-3.42-2.46-3.42-4.71M121,21a7.14,7.14,0,0,0,14.27,0A7.14,7.14,0,1,0,121,21m-14.77,6.72h3.55V21c0-1.75.56-4.53,3.13-4.53,2.28,0,2.3,2.25,2.3,3.92v7.28h3.54V19.1c0-3.07-1.4-5.35-4.63-5.35a5.07,5.07,0,0,0-4.29,2.17h-.06V7.85h-3.55ZM90,27.71h3.55V21c0-1.75.56-4.53,3.12-4.53,2.28,0,2.3,2.25,2.3,3.92v7.28h3.55V19.1c0-3.07-1.4-5.35-4.63-5.35a5.09,5.09,0,0,0-4.47,2.17h-.06V14.07H90ZM86.35,14.07H82.8v6.67c0,1.75-.56,4.53-3.12,4.53-2.28,0-2.3-2.25-2.3-3.92V14.07H73.83v8.6c0,3.07,1.4,5.35,4.63,5.35a5.68,5.68,0,0,0,4.47-2.17H83v1.85h3.36Zm-24.2,9.69c0-2.09,2.36-2.36,3.95-2.36h1.54a4.52,4.52,0,0,1-.74,2.81,3,3,0,0,1-2.49,1.22c-1.27,0-2.25-.51-2.25-1.67m-1.8-5.93a7.12,7.12,0,0,1,4.21-1.48c2.09,0,3.07.74,3.07,2.94h-2a10.78,10.78,0,0,0-4.58.79A4,4,0,0,0,58.76,24c0,2.59,2.36,4.05,4.76,4.05a4.87,4.87,0,0,0,4.21-2.36h.05a17.06,17.06,0,0,0,.16,2h3.12c-.08-1.06-.13-2-.16-3s-.05-2-.05-3.42V19.5c0-4-1.72-5.74-5.88-5.74a12.21,12.21,0,0,0-4.74,1ZM49.68,27.71h3.55v-5c0-2,0-5.56,2.89-5.56a3.6,3.6,0,0,1,1.59.29V13.86a3.86,3.86,0,0,0-1.09-.11,4.18,4.18,0,0,0-3.73,3.41h-.05v-3.1H49.68Zm-12.81,0h3.71V19.6h6.25V16.69H40.57V12.14h6.57V9.22H36.87Z"
        />
      </g>
      <path d="M152.92,37.12h1.79v9.25h-1.79Z"/>
      <path
        d="M160.12,38.42a2.05,2.05,0,0,0-.46.05,1.34,1.34,0,0,0-.46.2,1,1,0,0,0-.35.4,1.29,1.29,0,0,0-.14.64.71.71,0,0,0,.18.49,1.9,1.9,0,0,0,.45.36,5.54,5.54,0,0,0,.64.29l.7.3a5.37,5.37,0,0,1,.72.34A2.6,2.6,0,0,1,162,42a2.51,2.51,0,0,1,.45.7,3,3,0,0,1,.15,1,2.45,2.45,0,0,1-1,2.15,4.13,4.13,0,0,1-2.37.65,5.39,5.39,0,0,1-1.17-.11q-.49-.09-1.13-.25l.17-1.67a4,4,0,0,0,2,.57,2.12,2.12,0,0,0,1.06-.29,1,1,0,0,0,.52-.9,1,1,0,0,0-.15-.57,1.53,1.53,0,0,0-.45-.4,11.76,11.76,0,0,0-1.34-.61,4.87,4.87,0,0,1-.7-.37,3.4,3.4,0,0,1-.64-.48,2.51,2.51,0,0,1-.45-.7,2.39,2.39,0,0,1-.18-1,2.54,2.54,0,0,1,1-2.2A3.73,3.73,0,0,1,160,37a6.67,6.67,0,0,1,2.14.33l-.17,1.52A4.56,4.56,0,0,0,160.12,38.42Z"
      />
      <path
        d="M164.77,37.12H170v1.46h-3.48v2.28h3.19v1.46h-3.19v2.6h3.51v1.46h-5.3Z"
      />
    </svg>
  );
}
