const Root_URL = window.Root_URL;
const getUniqueID = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return s4() + s4() + "-" + s4();
};

/**
 * Gets Parameter from url string
 * @param param the item being queried
 * @param URL url string to parse
 * @returns string or undefined
 */
const getQueryParam = (URL) => {
  const params = {};
  URL.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(_, key, value) {
    params[key] = value;
  });
  return params;
};

const writeLocStore = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify({ value }));
};
const readLocStore = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
};

let reloadLoc = typeof reloadLocation !== "undefined";
const updateLocale = (e, lang, country) => {
  e.preventDefault();
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set("l",lang);
  urlParams.set("c",country);
  window.history.replaceState({},document.title,"?"+urlParams.toString());
  sessionStorage.setItem("locale", JSON.stringify({ lang, country }));
  window.location.reload();
  return;
};

/**
 *
 * @param {Boolean} bool
 * @param {Any} trueComponent The string or component to be displayed if bool is true
 * @param {Any} falseComponent The string or component to be displayed if bool is false
 */
const toggleDisplay = (bool, trueComponent, falseComponent) => {
  if (bool) return trueComponent;
  return falseComponent;
};
export {
  getUniqueID,
  getQueryParam,
  updateLocale,
  toggleDisplay,
  writeLocStore,
  readLocStore,
};
