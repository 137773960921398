const allAvailableOptions = ["DE", "CH", "ALL", "EU", "AL", "AM", "AT", "AZ", "BA", "BE", "BG", "BY", "CY", "CZ", "DK", "EE",
  "ES", "FI", "FR", "GE", "GR", "HR", "HU",  "IE", "IT", "LT", "LU", "LV", "MD", "ME", "MK", "MT", "NIE", "NL",
  "NO", "PL", "PT", "RO", "RS", "RU", "SE", "SI", "SK", "TR", "UA", "UK", "XK"];
	
const allAvailableOptionsWithBLAndTSO = allAvailableOptions.concat(["DE_BL","DE_TSO"]);
	
const allAvailableOptionsForEnergies = ["DE"];

const allEnergyOptions = ["DE", "CH", "ALL", "EU", "AL", "AM", "AT", "AZ", "BA", "BE", "BG", "BY", "CY", "CZ", "DK", "EE",
  "ES", "FI", "FR", "GE", "GR", "HR", "HU",  "IE", "IT", "LT", "LU", "LV", "MD", "ME", "MK", "MT", "NIE", "NL",
  "NO", "PL", "PT", "RO", "RS", "RU", "SE", "SI", "SK", "TR", "UA", "XK", "UK"];
  
const allOptionsWithoutEUandALLandUA = ["DE", "CH", "AL", "AM", "AT", "AZ", "BA", "BE", "BG", "BY", "CY", "CZ", "DK", "EE",
  "ES", "FI", "FR", "GE", "GR", "HR", "HU",  "IE", "IT", "LT", "LU", "LV", "MD", "ME", "MK", "MT", "NIE", "NL",
  "NO", "PL", "PT", "RO", "RS", "RU", "SE", "SI", "SK", "TR", "XK"];


const menuMap = [
  {
    main: "power",
    availableFor: allAvailableOptionsWithBLAndTSO,
    sub: [
      {
        name: "electricity-prod",
        url: "charts/power/chart.htm",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "electricity-prod-simulated",
        url: "charts/power_simulated/chart.htm",
        availableFor: ["DE"],
      },
      {
        name: "consumption-advice",
        url: "charts/consumption_advice/chart.htm",
        availableFor: allOptionsWithoutEUandALLandUA,
      },
      {
        name: "frequency",
        url: "charts/frequency/chart.htm",
        availableFor: ["DE"].concat(["DE_BL","DE_TSO"]),
      },
      {
        name: "installed-power",
        url: "charts/installed_power/chart.htm",
        availableFor: allAvailableOptions.concat(["DE_BL","DE_TSO"])
      },
      {
        name: "power-heatmaps",
        url: "charts/power_heatmaps/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "power-forecast",
        url: "charts/power_forecast/chart.htm",
        availableFor: allAvailableOptions.filter(item => item !== "EU" && item !== "ALL")
      },
      {
        name: "scatter-chart",
        url: "charts/power_scatter/chart.htm",
        availableFor: ["AL", "AT", "BA", "BE", "BG", "CH", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GE", "GR",
          "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MW", "MK", "NL", "NO", "PL", "PT", "RO", "RS", "SE", "SI", "SK",
          "UA", "XK"]
      },
        {
        name: "power_redispatch",
        url: "charts/power_redispatch/chart.htm",
        availableFor: ["ALL", "AT", "BE", "CH", "CZ", "DE", "DK", "EU", "FR", "HU", "NL", "NO", "PL", "RS", "SE", "SI", "UK"]
      },

    ],
  },
  {
    main: "energy",
    availableFor: allAvailableOptionsWithBLAndTSO,
    sub: [
      {
        name: "bar-chart",
        url: "charts/energy/chart.htm",
        availableFor: allAvailableOptions.concat(["DE_TSO","DE_BL"])
      },
      {
        name: "bar-chart-states",
        url: "charts/energy_states/chart.htm",
        availableFor: ["DE_BL"]
      },
      {
        name: "pie-chart",
        url: "charts/energy_pie/chart.htm",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "renewable",
        url: "charts/renewable_share/chart.htm",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "percent-load",
        url: "charts/percentage_full_load/chart.htm",
        availableFor: allEnergyOptions
      },
      {
        name: "storage",
        url: "charts/filling_level/chart.htm",
        availableFor: allEnergyOptions
      },
      {
        name: "import-export",
        url: "charts/import_export/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "energy-source-trade",
        url: "charts/energy_source_trade/chart.htm",
        availableFor: ["DE"]
      },
        {
        name: "energy_redispatch",
        url: "charts/energy_redispatch/chart.htm",
        availableFor: ["DE"]
      },
    ],
  },
  {
    main: "prices",
    availableFor: allAvailableOptions,
    sub: [
      {
        name: "spot-prices",
        url: "charts/price_spot_market/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "price-heatmaps",
        url: "charts/price_heatmaps/chart.htm",
        availableFor: ["AT", "BE", "BG", "CH", "CZ", "DE", "LU", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU",
          "IE", "IT", "LT", "LV", "ME", "NL", "NO", "PL", "PT", "RO", "RS", "SE", "SI", "SK", "UA"]
      },
      {
        name: "price-volume",
        url: "charts/price_volume/chart.htm",
        availableFor: ["DE", "CH"]
      },
      {
        name: "avg-spot",
        url: "charts/price_average/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "price_futures",
        url: "charts/price_futures/chart.htm",
        availableFor: ["AT", "CH", "DE", "FR"]
      },
      {
        name: "price_futures_bars",
        url: "charts/price_futures_bars/chart.htm",
        availableFor: ["AT", "CH", "DE", "FR", "ALL"]
      },
      {
        name: "scatter-spot",
        url: "charts/price_scatter/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "market-values",
        url: "charts/market_values/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "power-trade",
        url: "charts/power_trading/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "eeg-account",
        url: "charts/eeg_account/chart.htm",
        availableFor: ["DE"]
      },
    ],
  },
  {
    main: "environment",
    availableFor: ["EU", "ALL", "AT", "BE", "BG", "CH", "DE", "ES", "HR", "CZ", "DK", "FI", "FR", "GR", "HU", "IE",
      "IT", "LT", "NL", "PL", "PT", "RO", "RS","SE", "SI", "SK", "UK"],
    sub: [
      {
        name: "hourly-climate",
        url: "charts/climate_hours/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "annual-avg",
        url: "charts/climate_annual_average/chart.htm",
        availableFor: ["DE", "CH"]
      },
      {
        name: "hydrology",
        url: "charts/hydrology/chart.htm",
        availableFor: ["CH"]
      },
      {
        name: "co2-emissions",
        url: "charts/co2_emissions/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "power-emission",
        url: "charts/emissions/chart.htm",
        availableFor: ["EU", "AT", "BE", "BG", "DE", "HR", "CZ", "DK", "FI", "FR", "GR", "HU", "IE", "IT", "NL",
          "PL", "PT", "RO", "RS", "SI", "SK", "UK"]
      },
      {
        name: "radioactive-discharges",
        url: "charts/radioactive_discharges/chart.htm",
        availableFor: ["EU", "ALL", "BE", "BG", "CZ", "DE", "ES", "FI", "FR", "HU", "IT", "LT", "NL", "RO", "SE", "SI",
          "SK", "UK"]
      }
    ],
  },
  {
    main: "remod",
    availableFor: ["DE"],
    sub: [
      {
        name: "remod-installed-power",
        url: "charts/remod_installed_power/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "remod-application-technologies",
        url: "charts/remod_sector_data/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "remod-energies",
        url: "charts/remod_energies/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "remod-flexibility",
        url: "charts/remod_flexibility/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "remod-emissions",
        url: "charts/remod_emissions/chart.htm",
        availableFor: ["DE"]
      },
      {
        name: "remod-power-profiles",
        url: "charts/remod_power_profiles/chart.htm",
        availableFor: ["DE"]
      },
    ],
  },
  {
    main: "chartmaps",
    availableFor: allAvailableOptions,
    sub: [
      {
        name: "ren-share-map",
        url: "charts/renewable_share_map/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "import-export-map",
        url: "charts/import_export_map/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "price_average_map",
        url: "charts/price_average_map/chart.htm",
        availableFor: allAvailableOptions
      },
      {
        name: "infrastructure_meteodata",
        url: "map/map.htm",
        availableFor: allAvailableOptions
      },
    ],
  },
  {
    main: "info",
    availableFor: allAvailableOptionsWithBLAndTSO,
    sub: [
      {
        name: "api",
        url: "api.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "downloads",
        url: "downloads.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "presentations",
        url: "presentations.html",
        availableFor: ["DE"].concat(["DE_BL","DE_TSO"])
      },
      {
        name: "podcasts",
        url: "podcasts.html",
        availableFor: ["DE"].concat(["DE_BL","DE_TSO"])
      },      
      {
        name: "energy-charts-talks",
        url: "energy-charts-talks.html",
        availableFor: ["DE"].concat(["DE_BL","DE_TSO"])
      },
      {
        name: "explanations",
        url: "explanations.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "faqs",
        url: "faqs.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "sources",
        url: "sources.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
      {
        name: "contact",
        url: "contact.html",
        availableFor: allAvailableOptionsWithBLAndTSO
      },
    ],
  },
];

// Node.js or other CommonJS environment
if (typeof module !== 'undefined' && module.exports) {
    module.exports = menuMap;
} else {
    // Define as a global variable if the environment is neither module nor CommonJS
    window.menuMap = menuMap;
}

export { menuMap }; // ES6 export if needed