import React, { Component } from "react";
import ReactDOM from "react-dom";
import Nav from "../comps/nav";
import CLToggleComps from "../comps/CLToggleComps";

class App extends Component {
  render() {
    return <Nav />;
  }
}
class CLToggle extends Component {
  render() {
    return <CLToggleComps />;
  }
}

ReactDOM.render(<App />, document.getElementById("menu"));

//Handle element CLToggle which can only be called if the ID "CLToggle" is available in the DOM.
//for non chart pages using nav but not having chartSteering menu this ID is not availvalbe!
try {
  ReactDOM.render(<CLToggle />, document.getElementById("CLToggle"));
} catch (error) {
  //console.info("no chartSteering menu for CLToggle in this page!, " + error)
}
