import React, { useRef } from "react";
import LangToggle from "./langToggle";
import CountryToggle from "./countryToggle";
import { useTranslation } from "../hooks/customHooks";

export default function CLToggleComps() {
    
  const { t } = useTranslation();

  return (
    <>     
        <div className="rightMenuButtonVAlign">
            <CountryToggle />
            {t("countryLabel")}
        </div>
        <div className="rightMenuButtonVAlign">
            <LangToggle />
            {t("langLabel")}
        </div>
    </>
  );
}