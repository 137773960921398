import React, {
  Fragment,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import {
  DEFlag,
  EUFlag,
  CHFlag,
  FRFlag,
  BEFlag,
  DKFlag,
  ATFlag,
  CZFlag,
  CYFlag,
  LUFlag,
  NLFlag,
  PLFlag,
  SEFlag,
  ESFlag,
  FIFlag,
  ITFlag,
  NOFlag,
  PTFlag,
  UKFlag,
  GRFlag,
  HRFlag,
  HUFlag,
  IEFlag,
  NIEFlag,
  ROFlag,
  SIFlag,
  SKFlag,
  TRFlag,
  UAFlag,
  ALFlag,
  AMFlag,
  AZFlag,
  BAFlag,
  BGFlag,
  BYFlag,
  EEFlag,
  GEFlag,
  LTFlag,
  LVFlag,
  MDFlag,
  MEFlag,
  MKFlag,
  MTFlag,
  RSFlag,
  RUFlag,
  XKFlag,
} from "./svgs/icons";
import { updateLocale } from "../../utils/helperFunctions";
import { useTranslation } from "../hooks/customHooks";
import { Color } from "highcharts";

//import { translate } from "../global/javascript/language/translation";

// import { countryOptions } from "../utils/lang";
// const { countryOptions } = window.ecAppState;
const { isDevelopmentVersion } = window.ecAppState;

export function ActiveCountryFlag(){
  const [locale, setLocale] = useState(sessionStorage.getItem("locale"));
  const { lang, country } = JSON.parse(locale);

  const CF = new Map();
  CF.set("EU", <EUFlag className="flagIconAtLogo" />);  //Flag is identical for EU-continent and EU27
  CF.set("ALL", <EUFlag className="flagIconAtLogo" />); //Flag is identical for EU-continent and EU27
  CF.set("CH", <CHFlag className="flagIconAtLogo" />);
  CF.set("DE", <DEFlag className="flagIconAtLogo" />); //TSO, FedStates handled by IF at the end
  CF.set("FR", <FRFlag className="flagIconAtLogo" />);
  CF.set("BE", <BEFlag className="flagIconAtLogo" />);
  CF.set("DK", <DKFlag className="flagIconAtLogo" />);
  CF.set("AT", <ATFlag className="flagIconAtLogo" />);
  CF.set("CZ", <CZFlag className="flagIconAtLogo" />);
  CF.set("CY", <CYFlag className="flagIconAtLogo" />);
  CF.set("LU", <LUFlag className="flagIconAtLogo" />);
  CF.set("NL", <NLFlag className="flagIconAtLogo" />);
  CF.set("PL", <PLFlag className="flagIconAtLogo" />);
  CF.set("SE", <SEFlag className="flagIconAtLogo" />);
  CF.set("ES", <ESFlag className="flagIconAtLogo" />);
  CF.set("FI", <FIFlag className="flagIconAtLogo" />);
  CF.set("IT", <ITFlag className="flagIconAtLogo" />);
  CF.set("NO", <NOFlag className="flagIconAtLogo" />);
  CF.set("PT", <PTFlag className="flagIconAtLogo" />);
  CF.set("UK", <UKFlag className="flagIconAtLogo" />);
  CF.set("GR", <GRFlag className="flagIconAtLogo" />);
  CF.set("HR", <HRFlag className="flagIconAtLogo" />);
  CF.set("HU", <HUFlag className="flagIconAtLogo" />);
  CF.set("IE", <IEFlag className="flagIconAtLogo" />);
  CF.set("NIE", <NIEFlag className="flagIconAtLogo" />);
  CF.set("RO", <ROFlag className="flagIconAtLogo" />);
  CF.set("SI", <SIFlag className="flagIconAtLogo" />);
  CF.set("SK", <SKFlag className="flagIconAtLogo" />);
  CF.set("TR", <TRFlag className="flagIconAtLogo" />);
  CF.set("UA", <UAFlag className="flagIconAtLogo" />);
  CF.set("AL", <ALFlag className="flagIconAtLogo" />);
  CF.set("AM", <AMFlag className="flagIconAtLogo" />);
  CF.set("AZ", <AZFlag className="flagIconAtLogo" />);
  CF.set("BA", <BAFlag className="flagIconAtLogo" />);
  CF.set("BG", <BGFlag className="flagIconAtLogo" />);
  CF.set("BY", <BYFlag className="flagIconAtLogo" />);
  CF.set("EE", <EEFlag className="flagIconAtLogo" />);
  CF.set("GE", <GEFlag className="flagIconAtLogo" />);
  CF.set("LT", <LTFlag className="flagIconAtLogo" />);
  CF.set("LV", <LVFlag className="flagIconAtLogo" />);
  CF.set("MD", <MDFlag className="flagIconAtLogo" />);
  CF.set("ME", <MEFlag className="flagIconAtLogo" />);
  CF.set("MK", <MKFlag className="flagIconAtLogo" />);
  CF.set("MT", <MTFlag className="flagIconAtLogo" />);
  CF.set("RS", <RSFlag className="flagIconAtLogo" />);
  CF.set("RU", <RUFlag className="flagIconAtLogo" />);
  CF.set("XK", <XKFlag className="flagIconAtLogo" />);

  //check for DE subcountries and set DEFLAG at LOGO
  {if (country.startsWith("DE_")){
    CF.set(country, <DEFlag className="flagIconAtLogo" />);
    return (
      CF.get(country)
      );
    }
    else {
      return (
        CF.get(country)
      );
    }
  }
}

export default function CountryToggle() {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(sessionStorage.getItem("locale"));
  const [countryFilter, setCountryFilter] = useState("");
  const { lang, country } = JSON.parse(locale);

  const CF = new Map();
  CF.set("EU", <EUFlag className="flagIcon" />);  //Flag is identical for EU-continent and EU27
  CF.set("ALL", <EUFlag className="flagIcon" />); //Flag is identical for EU-continent and EU27
  CF.set("CH", <CHFlag className="flagIcon" />);
  CF.set("DE", <DEFlag className="flagIcon" />);
  CF.set("FR", <FRFlag className="flagIcon" />);
  CF.set("BE", <BEFlag className="flagIcon" />);
  CF.set("DK", <DKFlag className="flagIcon" />);
  CF.set("AT", <ATFlag className="flagIcon" />);
  CF.set("CZ", <CZFlag className="flagIcon" />);
  CF.set("CY", <CYFlag className="flagIcon" />);
  CF.set("LU", <LUFlag className="flagIcon" />);
  CF.set("NL", <NLFlag className="flagIcon" />);
  CF.set("PL", <PLFlag className="flagIcon" />);
  CF.set("SE", <SEFlag className="flagIcon" />);
  CF.set("ES", <ESFlag className="flagIcon" />);
  CF.set("FI", <FIFlag className="flagIcon" />);
  CF.set("IT", <ITFlag className="flagIcon" />);
  CF.set("NO", <NOFlag className="flagIcon" />);
  CF.set("PT", <PTFlag className="flagIcon" />);
  CF.set("UK", <UKFlag className="flagIcon" />);
  CF.set("GR", <GRFlag className="flagIcon" />);
  CF.set("HR", <HRFlag className="flagIcon" />);
  CF.set("HU", <HUFlag className="flagIcon" />);
  CF.set("IE", <IEFlag className="flagIcon" />);
  CF.set("NIE", <NIEFlag className="flagIcon" />);
  CF.set("RO", <ROFlag className="flagIcon" />);
  CF.set("SI", <SIFlag className="flagIcon" />);
  CF.set("SK", <SKFlag className="flagIcon" />);
  CF.set("TR", <TRFlag className="flagIcon" />);
  CF.set("UA", <UAFlag className="flagIcon" />);
  CF.set("AL", <ALFlag className="flagIcon" />);
  CF.set("AM", <AMFlag className="flagIcon" />);
  CF.set("AZ", <AZFlag className="flagIcon" />);
  CF.set("BA", <BAFlag className="flagIcon" />);
  CF.set("BG", <BGFlag className="flagIcon" />);
  CF.set("BY", <BYFlag className="flagIcon" />);
  CF.set("EE", <EEFlag className="flagIcon" />);
  CF.set("GE", <GEFlag className="flagIcon" />);
  CF.set("LT", <LTFlag className="flagIcon" />);
  CF.set("LV", <LVFlag className="flagIcon" />);
  CF.set("MD", <MDFlag className="flagIcon" />);
  CF.set("ME", <MEFlag className="flagIcon" />);
  CF.set("MK", <MKFlag className="flagIcon" />);
  CF.set("MT", <MTFlag className="flagIcon" />);
  CF.set("RS", <RSFlag className="flagIcon" />);
  CF.set("RU", <RUFlag className="flagIcon" />);
  CF.set("XK", <XKFlag className="flagIcon" />);

//check for DE subcountries and set DEFLAG at countryToggle
  {if (country.startsWith("DE_")){
    CF.set(country, <DEFlag className="flagIcon" />);
  }}

  /* Temp Measures
   * introduced to allow specific selection of language (import utils/lang.js deactivated).
   * "value" is imortant and overrules utils/lang.js settings. Allows hidden use of country (not listed in menu),
   * "languages" not used here but kept for same syntax
   */
  const countryOptions  =  {
      //countries with full available content
      DE: { value: t("de_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      //DE TSOs
      // DE_TSO: { value: t("de_tso_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_50H: { value: t("de_50h_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_TEN: { value: t("de_ten_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_AMP: { value: t("de_amp_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_TBW: { value: t("de_tbw_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      //DE Federal States
      DE_BL: { value: t("de_bl_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_BW: { value: t("de_bw_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_BY: { value: t("de_by_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_BE: { value: t("de_be_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_BB: { value: t("de_bb_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_HB: { value: t("de_hb_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_HH: { value: t("de_hh_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_HE: { value: t("de_he_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_MV: { value: t("de_mv_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_NI: { value: t("de_ni_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_NW: { value: t("de_nw_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_RP: { value: t("de_rp_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_SL: { value: t("de_sl_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_SN: { value: t("de_sn_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_ST: { value: t("de_st_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_SH: { value: t("de_sh_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },
      // DE_TH: { value: t("de_th_Ctoggle"), languages: ["de", "en", "fr", "it"], submenu: "DE" },

      CH: { value: t("ch_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      //countries with reduced website/data content
      EU: { value: t("eu_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      ALL: { value: t("all_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      //
      AL: { value: t("al_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      AM: { value: t("am_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      AT: { value: t("at_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      AZ: { value: t("az_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      BA: { value: t("ba_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      BE: { value: t("be_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      BG: { value: t("bg_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      BY: { value: t("by_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      CY: { value: t("cy_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      CZ: { value: t("cz_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      DK: { value: t("dk_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      EE: { value: t("ee_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      ES: { value: t("es_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      FI: { value: t("fi_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      FR: { value: t("fr_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      GE: { value: t("ge_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      GR: { value: t("gr_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      HR: { value: t("hr_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      HU: { value: t("hu_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      IE: { value: t("ie_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      IT: { value: t("it_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      LT: { value: t("lt_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      LU: { value: t("lu_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      LV: { value: t("lv_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      MD: { value: t("md_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      ME: { value: t("me_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      MK: { value: t("mk_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      MT: { value: t("mt_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      NIE: { value: t("nie_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      NL: { value: t("nl_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      NO: { value: t("no_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      PL: { value: t("pl_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      PT: { value: t("pt_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      RO: { value: t("ro_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      RS: { value: t("rs_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      RU: { value: t("ru_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      SE: { value: t("se_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      SI: { value: t("si_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      SK: { value: t("sk_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      TR: { value: t("tr_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      UA: { value: t("ua_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      UK: { value: t("uk_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      XK: { value: t("xk_Ctoggle"), languages: ["de", "en", "fr", "it"] },
      //countries hidden (but available in development)
      //GB: { value: t("gb_Ctoggle")", languages: ["de", "en", "fr", "it"] }, //hidden    
  };

  const countryList = Object.keys(countryOptions).map((key) => {
    return {
      key,
      value: countryOptions[key].value,
    };
  });

  useEffect(() => {
    // since jquery is imported in all files and it already controls bootstrap's dropdown it makes sense to control input element with it also. The listener has to be put on the topmost parent for it to work.
    $ &&
      $("#countryDropDownParent").on("shown.bs.dropdown", function() {
        $("#countryFilterInput").focus();
      });
    return () => {};
  }, []);

  return (
    <span
      className="btn-group"
      data-toggle="tooltip"
      title={t("countryChange")}
      id="countryDropDownParent"
    >
      <button
        type="button"
        className="langToggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        id="countryDropdown"
      >
        {CF.get(country)}
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        style={{ minWidth: "10px", padding: ".5rem" }}
      >
        <p className="dropdown-header" style={{ padding: "0 0 0.2rem 0" }}>
          {t("countryChange")}
        </p>
        <input
          id="countryFilterInput"
          className="form-control"
          type="text"
          value={countryFilter}
          placeholder={t("search")}
          onChange={(e) => setCountryFilter(e.target.value)}
        ></input>
        <div className="dropdown-divider"></div>
        <div className="pre-scrollable scrollable-menu-overflow">
          {/*<p className="dropdown-header" style={{ padding: "0 1em" }}>
          {"test"}
          </p>*/}
          {countryList.map(({ key, value }, i) => {
            // check if the value matches the search/countryFilter
            if (
              !value.toLowerCase().includes(countryFilter.toLocaleLowerCase())
            ) {
              return "";
            } else if (key == "CH" || key == "ALL") {
              //these keys define not clickable separation lines in country select list after "key = CH or ALL"
              return (
                <Fragment key={`ct_1-${i}`}>
                  <button
                    className={
                      "dropdown-item stopHover" +
                      ((key == country && " active") || "")
                    }
                    aria-label="toggle country"
                    type="button"
                    style={{
                      padding: "0",
                      lineHeight: "0",
                      marginBottom: ".2rem",
                      pointerEvents: "auto",
                    }}
                    onClick={(e) => updateLocale(e, lang, key)}
                    key={`cntry_opt${i}`}
                  >
                    <p className="optText-nav">{value}</p>
                  </button>
                  <div className="dropdown-divider ec-dropdown-divider-color"></div>
                </Fragment>
              );
            }
            else if(countryOptions[key].submenu=="DE") {
              return(
                <Fragment key={`ct_2-${i}`}>
                  <button
                    key={`ct_2-${i}`}
                    className={
                      "dropdown-item stopHover btn btn-secondary btn-sm" +
                      ((key == country && " active") || "")
                    }
                    aria-label="toggle country"
                    type="button"
                    style={{
                      width: "90%",
                      padding: "0",
                      lineHeight: "0",
                      marginBottom: ".2rem",
                      pointerEvents: "auto",
                      marginLeft: "1rem",
                      backgroundColor: "silver",
                    }}
                    onClick={(e) => updateLocale(e, lang, key)}
                    key={`cntry_opt_2${i}`}
                    >
                    <p className="optText-nav">{value}</p>
                  </button>
                    {key == "DE_TBW" && <div className="dropdown-divider ec-dropdown-divider-color" style={{width:"90%",marginLeft: "1rem"}}></div>}
              </Fragment>
              )
            } else {
              return (
                <button
                  key={`ct_2-${i}`}
                  className={
                    "dropdown-item stopHover" +
                    ((key == country && " active") || "")
                  }
                  aria-label="toggle country"
                  type="button"
                  style={{
                    padding: "0",
                    lineHeight: "0",
                    marginBottom: ".2rem",
                    pointerEvents: "auto",
                  }}
                  onClick={(e) => updateLocale(e, lang, key)}
                  key={`cntry_opt${i}`}
                >
                  <p className="optText-nav">{value}</p>
                </button>
              );
            }
          })}
        </div>
      </div>
    </span>
  );
}
